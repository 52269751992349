import React from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import { ContentfulPage } from "../../types/graphql-types";
import { usePages } from "../hooks";

const Footer = () => {
  const { pathname } = useLocation();
  const { pages } = usePages();

  return (
    <>
      <footer className={pathname === "/" ? "" : "footer-home"}>
        <div className="container">
          <div className="footer-row">
            <div className="footer-row__column">
              <Link className="footer-row__column__item" to="/">
                Hem.
              </Link>
              {pages?.map(({ slug, id, title }: ContentfulPage) => (
                <Link
                  to={`/${slug?.toLowerCase()}/`}
                  key={id}
                  className="footer-row__column__item"
                >
                  {title}.
                </Link>
              ))}
            </div>
            <div className="footer-row__column">
              <a className="footer-row__column__item footer-row__column__item__copy">
                @ Copyright {new Date().getFullYear()} Barnstorlekar.se
              </a>
              <a className="footer-row__column__item" href="/kontakt">
                Kontakt
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
