import { Link, graphql } from "gatsby";
import React, { useState, useEffect } from "react";
import { ContentfulPage } from "../../types/graphql-types";
import { useLocation } from "@reach/router";
import { usePages } from "../hooks";

const Header = () => {
  const { pathname } = useLocation();
  const [showMobileMenu, setMobileMenu] = useState(false);

  const { pages } = usePages();

  useEffect(() => {
    setMobileMenu(false);
  }, [pathname]);

  return (
    <header className="site-header">
      <div className="container">
        <div className="nav">
          <Link to="/" className="nav__item logo">
            Barnstorlekar
          </Link>
          <div className="main-nav">
            <Link to="/" className="nav__item">
              Hem.
            </Link>
            {pages?.map(({ slug, id, title }: ContentfulPage) => (
              <Link
                to={`/${slug?.toLowerCase()}`}
                key={id}
                className="nav__item"
              >
                {title}.
              </Link>
            ))}
          </div>
          <div
            className={`mobile-toogle ${showMobileMenu ? "on" : ""}`}
            onClick={() => setMobileMenu(!showMobileMenu)}
          >
            <div className="one" />
            <div className="two" />
            <span className="mobile-toogle-text">Meny</span>
          </div>
        </div>
        <div className={`mobile-menu ${showMobileMenu ? "on" : ""}`}>
          <Link to="/" className="nav__item" onClick={() => {}}>
            Hem.
          </Link>
          {pages?.map(({ slug, id, title }: ContentfulPage) => (
            <Link
              to={`/${slug && slug.toLowerCase()}`}
              key={id}
              className="nav__item"
            >
              {title}.
            </Link>
          ))}
        </div>
      </div>
    </header>
  );
};

export default Header;
