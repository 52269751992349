import { useStaticQuery, graphql } from "gatsby";

export const usePages = () => {
  const {
    contentfulMenu: { pages },
  } = useStaticQuery(graphql`
    query testQuery {
      contentfulMenu {
        id
        pages {
          id
          title
          slug
        }
      }
    }
  `);

  return { pages };
};
